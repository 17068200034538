import request from '@/utils/request';

export function getProductsFromCategory(category, options) {
  return request({
    url: `/categories/${category}/products${options !== undefined ? `?options${options.length ? `=${options.join(encodeURIComponent('+'))}` : ''}` : ''}`,
    method: 'get'
  });
}

export function getProductsFromProp(prop, options) {
  return request({
    url: `/props/${prop}/products${options !== undefined ? `?options${options.length ? `=${options.join(encodeURIComponent('+'))}` : ''}` : ''}`,
    method: 'get'
  });
}

export function getProductsFromBrand(brand, options) {
  return request({
    url: `/brands/${brand}/products${options !== undefined ? `?options${options.length ? `=${options.join(encodeURIComponent('+'))}` : ''}` : ''}`,
    method: 'get'
  })
}

export function postProductInCategory(category, data) {
  return request({
    url: `/categories/${category}/products`,
    method: 'post',
    data
  })
}

export function postProducts(data) {
  return request({
    url: '/products',
    method: 'post',
    data
  })
}

export function putProduct(id, data) {
  return request({
    url: `/products/${id}`,
    method: 'post',
    data
  })
}

export function deleteProduct(id) {
  return request({
    url: `/products/${id}`,
    method: 'delete',
  })
}